import { gql } from '@apollo/client'

export default gql`
  fragment profileFragments on Profile {
    email
    authToken
    balance
    creditBalance
    username
    avatar
    referralsCount
    referralCode
    canWithdraw
    accumulatedBet
    accumulatedBetLeft
    totalEffectiveAmount
    totalEffectiveXimaAmount
    ximaTotal
    ximaWay
    vip
    currentTime
    role
    winLimit
    totalWinAmount
    hideWinPoints
    dividend
    nickname
    tableLimitMin
    tableLimitMax
    beta
    isTrial
    lineNotifyToken
    dragonCount
    jwtToken
    platformDisableAdult
    platformDisableCustomerService
  }
`
