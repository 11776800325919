import { useCallback } from 'react'

import { createGlobalState } from 'react-use'

const useGlobalState = createGlobalState(
  window.localStorage.getItem('agreeTerms'),
)

export default function useToken(): [string, any, any] {
  const [agree, setAgree] = useGlobalState()

  const update = useCallback(
    (token) => {
      setAgree(token)
      window.localStorage.setItem('agreeTerms', token)
    },
    [setAgree],
  )

  const remove = useCallback(() => {
    window.localStorage.removeItem('agreeTerms')
  }, [])

  return [agree, update, remove]
}
