import { gql } from '@apollo/client'

export default gql`
  query wallet {
    wallet {
      id
      balance
      creditBalance
      totalEffectiveAmount
      totalEffectiveXimaAmount
    }
  }
`
