import { gql } from '@apollo/client'
import profileFragments from 'graphql/application/fragments/profileFragments'

export default gql`
  query profile {
    profile {
      id
      ...profileFragments
      needUpdatePassword
    }
  }

  ${profileFragments}
`
