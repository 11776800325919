import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'

import * as Sentry from '@sentry/browser'

import useAgreeTerms from './useAgreeTerms'
import useToken from './useToken'

const Auth = createContext(null)

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        outdated: false,
      }
    case 'LOADING':
      return { ...state, loading: true }
    case 'ERROR':
      return { ...state, loading: false, error: action.error }
  }
}

const initialState = {
  isAuthenticated: false,
  loading: false,
  error: null,
}

const Provider = ({ children }) => {
  const [, updateToken, removeToken] = useToken()
  const [, , removeAgreeTerms] = useAgreeTerms()
  const [state, dispatch] = useReducer(reducer, initialState)

  const authenticate = useCallback(() => {
    dispatch({ type: 'AUTHENTICATED' })
  }, [dispatch])

  const setError = useCallback(
    (error) => {
      dispatch({ type: 'ERROR', error })
    },
    [dispatch],
  )

  const logout = useCallback(() => {
    Sentry.configureScope((scope) => scope.setUser(null))
    removeToken()
    removeAgreeTerms()
    window.location.href = '/'
  }, [removeToken, removeAgreeTerms, dispatch])

  return (
    <Auth.Provider
      value={[state, { authenticate, login: updateToken, setError, logout }]}
    >
      {children}
    </Auth.Provider>
  )
}

export const AuthProvider = React.memo(Provider)

export default function useAuth() {
  return useContext(Auth)
}
