import React, { useCallback } from 'react'

import delay from 'helpers/delay'
import { useSnackbar } from 'notistack'

import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

export default function JackpotListener() {
  const { enqueueSnackbar } = useSnackbar()

  const handleJackpot = useCallback(async ({ jackpot }) => {
    await delay(2000)
    const winLossAmount = parseFloat(jackpot.winLossAmount)
    const variant =
      winLossAmount === 0 ? 'warning' : winLossAmount > 0 ? 'success' : 'error'

    enqueueSnackbar(
      `${jackpot.roomName} 輸贏：${winLossAmount} 餘額：${jackpot.balance}`,
      {
        variant,
        preventDuplicate: true,
        autoHideDuration: 2000,
      },
    )

    window.dispatchEvent(new CustomEvent('jackpot', { detail: jackpot }))
  }, [])

  return (
    <ActionCableConsumer
      onReceived={handleJackpot}
      channel={{ channel: 'JackpotChannel' }}
    />
  )
}
