import { useEffect } from 'react'

export default function useOnReconnect(callback) {
  useEffect(() => {
    window.addEventListener('online', callback)

    return () => {
      window.removeEventListener('online', callback)
    }
  }, [callback])
}
