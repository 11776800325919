import { useMemo } from 'react'

import { round } from 'lodash'
import { useProfileTableLimit } from 'providers/richman/ProfileProvider'

export enum BaccaratRate {
  BIG = 0.53,
  SMALL = 1.45,
  DEALER = 0.95,
  PLAYER = 1,
  TIE = 8,
  PLAYER_PAIR = 11,
  DEALER_PAIR = 11,
  SUPER_6 = 20,
  SUPER_6x2 = 12,
  SUPER_6x3 = 20,
  DEALER_DRAGON = 30,
  PLAYER_DRAGON = 30,
  DEALER_SINGLE = 0.94,
  PLAYER_SINGLE = 0.96,
  DEALER_DOUBLE = 0.94,
  PLAYER_DOUBLE = 0.9,
  PERFECT_PAIR = 25,
  ANY_PAIR = 5,
  DEALER_KING = 4,
  PLAYER_KING = 4,
  SUPER_TIE_0 = 150,
  SUPER_TIE_1 = 215,
  SUPER_TIE_2 = 225,
  SUPER_TIE_3 = 200,
  SUPER_TIE_4 = 120,
  SUPER_TIE_5 = 110,
  SUPER_TIE_6 = 45,
  SUPER_TIE_7 = 45,
  SUPER_TIE_8 = 80,
  SUPER_TIE_9 = 80,
}

export const targets = [
  'big',
  'small',
  'dealer',
  'player',
  'tie',
  'playerPair',
  'dealerPair',
  'dealerDragon',
  'playerDragon',
  'dealerSingle',
  'playerSingle',
  'dealerDouble',
  'playerDouble',
  'perfectPair',
  'anyPair',
  'dealerKing',
  'playerKing',
  'superTie0',
  'superTie1',
  'superTie2',
  'superTie3',
  'superTie4',
  'superTie5',
  'superTie6',
  'superTie7',
  'superTie8',
  'superTie9',
  'super6',
]

function calcXianHong(limit, odds) {
  if (odds < 1) {
    return limit
  } else {
    return round(limit / odds, -1)
  }
}

export default function useXianHong() {
  const { tableLimitMin, tableLimitMax } = useProfileTableLimit()

  return useMemo(() => {
    if (tableLimitMin && tableLimitMax) {
      return {
        big: {
          min: calcXianHong(tableLimitMin, BaccaratRate.BIG),
          max: calcXianHong(tableLimitMax, BaccaratRate.BIG),
        },
        small: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SMALL),
          max: calcXianHong(tableLimitMax, BaccaratRate.SMALL),
        },
        dealer: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER),
        },
        player: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER),
        },
        tie: {
          min: calcXianHong(tableLimitMin, BaccaratRate.TIE),
          max: calcXianHong(tableLimitMax, BaccaratRate.TIE),
        },
        playerPair: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER_PAIR),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER_PAIR),
        },
        dealerPair: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER_PAIR),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER_PAIR),
        },
        super6: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_6),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_6),
        },
        dealerDragon: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER_DRAGON),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER_DRAGON),
        },
        playerDragon: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER_DRAGON),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER_DRAGON),
        },
        dealerSingle: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER_SINGLE),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER_SINGLE),
        },
        playerSingle: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER_SINGLE),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER_SINGLE),
        },
        dealerDouble: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER_DOUBLE),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER_DOUBLE),
        },
        playerDouble: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER_DOUBLE),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER_DOUBLE),
        },
        perfectPair: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PERFECT_PAIR),
          max: calcXianHong(tableLimitMax, BaccaratRate.PERFECT_PAIR),
        },
        anyPair: {
          min: calcXianHong(tableLimitMin, BaccaratRate.ANY_PAIR),
          max: calcXianHong(tableLimitMax, BaccaratRate.ANY_PAIR),
        },
        dealerKing: {
          min: calcXianHong(tableLimitMin, BaccaratRate.DEALER_KING),
          max: calcXianHong(tableLimitMax, BaccaratRate.DEALER_KING),
        },
        playerKing: {
          min: calcXianHong(tableLimitMin, BaccaratRate.PLAYER_KING),
          max: calcXianHong(tableLimitMax, BaccaratRate.PLAYER_KING),
        },
        superTie0: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_0),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_0),
        },
        superTie1: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_1),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_1),
        },
        superTie2: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_2),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_2),
        },
        superTie3: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_3),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_3),
        },
        superTie4: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_4),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_4),
        },
        superTie5: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_5),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_5),
        },
        superTie6: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_6),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_6),
        },
        superTie7: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_7),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_7),
        },
        superTie8: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_8),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_8),
        },
        superTie9: {
          min: calcXianHong(tableLimitMin, BaccaratRate.SUPER_TIE_9),
          max: calcXianHong(tableLimitMax, BaccaratRate.SUPER_TIE_9),
        },
      }
    }
  }, [tableLimitMin, tableLimitMax])
}
