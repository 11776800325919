import { useCallback, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function refreshWhenTokenChanged(newToken, path = null) {
  const timer = setInterval(() => {
    if (newToken === window.localStorage.getItem('userToken')) {
      clearInterval(timer)
      if (path) {
        window.location.href = `/#/${path}`
      } else {
        window.location.href = '/'
      }
    }
  }, 250)
}

export default function useToken(): [string, any, any] {
  const update = useCallback((newToken, path) => {
    window.localStorage.setItem('userToken', newToken)
    refreshWhenTokenChanged(newToken, path)
  }, [])

  const remove = useCallback(() => {
    window.localStorage.removeItem('userToken')
    refreshWhenTokenChanged(null)
  }, [])

  const token = useMemo(() => {
    const localStorageToken = window.localStorage.getItem('userToken')
    const metaToken = document
      .querySelector('meta[name="jwt_token"]')
      ?.getAttribute('content')

    if (metaToken) {
      const path = document
        .querySelector('meta[name="path"]')
        ?.getAttribute('content')
      update(metaToken, path)
      return metaToken
    } else {
      return localStorageToken
    }
  }, [])

  return [token, update, remove]
}
