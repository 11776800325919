import React, { createContext, useCallback, useContext, useMemo } from 'react'

import GET_WALLET from 'graphql/application/queries/getWallet'
import delay from 'helpers/delay'

import { useQuery } from '@apollo/client'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

import useOnReconnect from './useOnReconnect'

const WalletContext = createContext(null)

const Provider = ({ children }) => {
  const { data, updateQuery, refetch } = useQuery(GET_WALLET, {
    fetchPolicy: 'cache-first',
  })
  const { wallet } = data || {}

  const channel = useMemo(() => ({ channel: 'WalletChannel' }), [])

  const onReceived = useCallback(
    async (response) => {
      await delay(2000)
      updateQuery((prev) => {
        return { wallet: { ...prev.wallet, ...response.wallet } }
      })
    },
    [updateQuery],
  )

  useOnReconnect(refetch)

  return (
    <ActionCableConsumer channel={channel} onReceived={onReceived}>
      <WalletContext.Provider value={[wallet || {}, { updateQuery }]}>
        {children}
      </WalletContext.Provider>
    </ActionCableConsumer>
  )
}

export const WalletProvider = React.memo(Provider)

export default function useWallet() {
  return useContext(WalletContext)
}
