import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import JackpotListener from 'components/richman/JackpotListener'
import GET_PROFILE from 'graphql/application/queries/getProfile'
import useAuth from 'hooks/useAuth'
import { WalletProvider } from 'hooks/useWallet'
import { useHistory, useLocation } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const ProfileContext = createContext(null)
const ProfileTableLimitContext = createContext(null)

const ProfileProvider: React.FC = ({ children }) => {
  const { data, refetch } = useQuery(GET_PROFILE)
  const { profile } = data || {}
  const [needUpdatePassword, setNeedUpdatePassword] = useState(
    profile?.needUpdatePassword || false,
  )
  const [_, { logout }] = useAuth()
  const [balance, setBalance] = useState(0.0)

  useEffect(() => {
    if (profile) {
      setBalance(profile.balance)
      setNeedUpdatePassword(profile.needUpdatePassword)
    }
  }, [profile])

  const onReceived = (response) => {
    const { profile: newProfile } = response
    setBalance(newProfile.balance)
    if (newProfile.needUpdatePassword) {
      setNeedUpdatePassword(newProfile.needUpdatePassword)
    }
    if (newProfile.jwtToken === null) {
      logout()
    }
  }

  const channel = useMemo(
    () =>
      profile && { channel: 'ProfileChannel', profileId: String(profile.id) },
    [profile?.id],
  )

  const tableLimits = useMemo(() => {
    return {
      tableLimitMin: profile?.tableLimitMin,
      tableLimitMax: profile?.tableLimitMax,
    }
  }, [profile?.tableLimitMin, profile?.tableLimitMax])

  if (channel) {
    return (
      <ActionCableConsumer channel={channel} onReceived={onReceived}>
        <ProfileTableLimitContext.Provider value={tableLimits}>
          <ProfileContext.Provider
            value={{ profile, refetch, needUpdatePassword }}
          >
            <JackpotListener />
            <WalletProvider>{children}</WalletProvider>
          </ProfileContext.Provider>
        </ProfileTableLimitContext.Provider>
      </ActionCableConsumer>
    )
  } else {
    return (
      <ProfileContext.Provider value={{ profile, refetch }}>
        {children}
      </ProfileContext.Provider>
    )
  }
}
export const useProfile = () => useContext(ProfileContext)
export const useProfileTableLimit = () => useContext(ProfileTableLimitContext)

export default ProfileProvider
